import React from 'react';
import styled from 'styled-components';

import { Layout, Link, SEO, Small, WaveSection } from 'src/components';

const Projects = styled.div`
  section > :first-child {
    margin-top: 0;
  }
`;

const ProjectsPage = () => (
  <Layout activePage="projects">
    <SEO title="Projects" />
    <section>
      <p>Here's an overview of some of the projects I've built or made significant contributions to.</p>
    </section>

    <Projects>
      <WaveSection as="section">
        <h3>StackTrends</h3>
        {/* <h3>
          <QueryImage
            name="harmony"
            style={{
              display: 'inline-block',
              marginRight: '10px',
              verticalAlign: 'middle',
              width: 64
            }}
          />{' '}
          Harmony
				</h3> */}
        <p>
          StackTrends is a website devoted to software engineering trend data. The data includes such metrics as how
          many developer jobs are available and what languages and frameworks are most or least in demand. All data can
          be viewed within the context of a location, whether that's by city, state, or nationwide.
        </p>
        <p>
          It started its life as a proof-of-concept that got perpetually iterated on because it was my test-bed for
          trying out new liraries and frameworks. Whenever a new technology was proposed at work I would implement it
          here first to see how it handled; it was a low-risk environment and had plenty of data to work with for
          testing at scale.
        </p>
        <p>
          At some unknown point StackTrends became a serious thing for me. It may have been because people knew it
          existed when I went to conferences or when I started to get emails from people asking questions about the
          data, I'm not entirely sure. Now I devote as much time as I'm able to it.
        </p>
        <p>
          StackTrends has a stable monthly audience with modest growth and fields regular requests for custom datasets.
        </p>
        <Small>
          <Link to="https://stacktrends.dev/" button>
            Check Out StackTrends
          </Link>
          {/* &nbsp;
          <Link to="https://refract.js.org/" secondary button>
            Documentation
          </Link>
          &nbsp;
          <Link to="https://github.com/fanduel-oss/refract" secondary button>
            <Icon name="GitHub" /> Code
          </Link> */}
        </Small>
      </WaveSection>

      <section>
        <h3>Lash Fancy</h3>
        <p>Lash Fancy is a website that curates regional eyelash extension artist information.</p>
        <p>
          The project got its start when I was contracted to crawl websites for eyelash artist data; it was scattered
          across many domains and often hard to find. Rather than toss the data out afterwards, my co-founder and I
          wrapped a site around it and encouraged artists to claim their pages and provide more detail to make them
          easier to find in search engines.
        </p>
        <p>
          What started out as a thought experiment has been become one of the most, if not the most, comprehensive
          souces for this type of information in the country and continues to grow in unique visitors each month.
        </p>
        <Small>
          <Link to="https://www.lashfancy.com/" button>
            Check Out Lash Fancy
          </Link>
        </Small>
      </section>
    </Projects>
  </Layout>
);

export default ProjectsPage;
